// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // apiUrl: 'https://repository.isservices.co.za/bannerprocessing/process.aspx',
  oldURl : 'https://repository.isservices.co.za/bannerprocessing/process.aspx',
  crmApiUrl: 'https://luhambomarketing.co.za/Luhambo-CRM-dev/apis/db-api.php',
  localeApi: 'https://www.jeffasenmusic.co.za/api_caller.php',
  tracker: {
    trackerUsername: 'Gilela',
    trackerPassword: 'Tracker@112#',
    sourceId: '55387',
    trackerSubmitterApi: 'http://ndwfinancialservices.co.za/apis/tracker-submitter.php',
    trackerWriterApi: 'http://ndwfinancialservices.co.za/apis/tracker-writer.php'
  },
  readnamesApi: 'https://api-hub.shayelasolutions.co.za/api/v1/campaigns/read-sheet/ndw-financial',
  purpleWaterWritterApi:'https://api-hub.shayelasolutions.co.za/api/v1/campaigns/write-to-sheet/ndw-financial',
  purpleWaterToken: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJBMDUxRlEyMlpQMSIsInN1YiI6IlUwNTFXOUtBODhLIiwiZXhwIjoxNzUxOTc4OTgwLCJpYXQiOjE3MjA0NDI5ODB9.G5Tqy8ILM2da_83I8nuGIZBaLB5MJWF2W5O3gk73t44",
  readerApi: 'https://ndw.motloungtech.africa/Readers/at-readnames.php',
  lifeWriterApi: 'https://ndw.motloungtech.africa/Writers/atLifeWriter.php',
  dialDirectWriterApi: 'https://ndw.motloungtech.africa/Writers/dialDirectWriter.php',
  firstForWomenWriterApi: 'https://ndw.motloungtech.africa/Writers/firstForWomenWriter.php',
  kingPriceWriterApi: 'https://ndw.motloungtech.africa/Writers/kingPriceSheetWriter.php',
  kingPriceSubmitterApi: 'https://ndw.motloungtech.africa/Submitters/king-price-submitter.php',
  readNamesApi: 'https://ndw.motloungtech.africa/Readers/at-readnames.php',
  autoAndGeneralWriterApi: 'https://ndw.motloungtech.africa/Writers/autoAndGeneralWriter.php',
  autoAndGeneralVasWriterApi: 'https://ndw.motloungtech.africa/Writers/autoAndGeneralVasWriter.php',
  budgetInsuranceWriterApi: 'https://ndw.motloungtech.africa/Writers/budgetInsuranceWriter.php',
  AVMWriterApi: 'https://ndw.motloungtech.africa/Writers/AvmWriter.php',
  budgetInsuranceWriterVasApi: 'https://ndw.motloungtech.africa/Writers/budgetInsuranceVasWriter.php',
  firebaseConfig: {
    apiKey: "AIzaSyDFvFfWCmZFgiJC8XwMpQBVvV20k4v6Ol8",
    authDomain: "potholeregistrydb.firebaseapp.com",
    projectId: "potholeregistrydb",
    storageBucket: "potholeregistrydb.appspot.com",
    messagingSenderId: "247147878293",
    appId: "1:247147878293:web:9167d0f10aaee07dadc89c",
    measurementId: "G-NY3KE1Q2DD"
  },
  cartrack: {
    token: 'f8fd084a-7e88-4c09-b8e7-2b080bfd93d1',
    campaignUid: '3ea9b287-bf99-4582-a625-44dc411a37e8',
    apiUrl: 'https://ndw.motloungtech.africa/Submitters/car-track-submitter.php',
    writerUrl: 'https://ndw.motloungtech.africa/Writers/fvCarTrackSheetWriter.php',
    readUrl: 'https://ndw.motloungtech.africa/Readers/readnames.php'
  },
  campaigns: [{
    name: 'virseker',
    title: 'Virseker',
    type: 'custom', //affiliate || telesure || custom
    path: 'virseker',
    imgSrc: '',
    campaignMeta: {
      url: 'https://repository.isservices.co.za/bannerprocessing/process.aspx',
      currentDate: {
        set: false,
        apiKey: 'date'
      },
      dateOfBirth: {
        set: false,
        apiKey: '' // value of dateOfBirth must live in controls as "dateOfBirth"
      }
    },
    integrationDocUrl: '',
    apiUrl: 'https://repository.isservices.co.za/bannerprocessing/process.aspx', // see readme to run light-web-server, this will help you run your php locally
    inputs: [
      {
        name: 'name',
        label: 'Firstname',
        type: 'input',
        inputType: 'text',
        placeholder: '',
        validations: [
          {
            validation: 'required',
            message: 'Please enter valid Firstname'
          }
        ]
      },
      {
        name: 'surname',
        label: 'Surname',
        type: 'input',
        inputType: 'text',
        placeholder: '',
        validations: [
          {
            validation: 'required',
            message: 'Please enter valid Surname'
          }
        ]
      },
      {
        name: 'tel',
        label: 'Phone Number',
        type: 'input',
        inputType: 'number',
        placeholder: '',
        validations: [
          {
            validation: 'required',
            message: 'Please enter valid Phone Number'
          },
          {
            validation: 'minlength',
            message: 'Please enter valid Phone Number'
          },
          {
            validation: 'maxlength',
            message: 'Please enter valid Phone Number'
          }
        ]
      },
      {
        name: 'vdn',
        label: 'Campaign Type',
        type: 'dropdown',
        inputType: '',
        placeholder: '',
        options: [
          {name: 'VDNDW', label: 'Warmup', value: 'VDNDW'}
        ],
        validations: [
        ]
      },
      {
        name: 'agentName',
        label: 'Agent Name:',
        type: 'dropdown',
        inputType: '',
        placeholder: '',
        options: [
        ],
        validations: [
        ]
      }
    ],
    sheetConfig: {
      applicationName: 'NDW',
      spreadSheetId: '1KCeGezSfTbdoQS2318cFe-Saf7uZ9MnTTSJQBNcg4Vw',
      developerKey: 'AIzaSyB86WbfRkO-rdcJAPuWBNqQbSftEKBy9E0',
      writer: {
        sheetName: 'Virseker',
        url: 'https://ndw.motloungtech.africa/Writers/writer-template.php'
      },
      reader: {
        sheetName: 'agent-names',
        url: 'https://ndw.motloungtech.africa/Readers/readnames-template.php',
        set: true
      }
    }
  }],
    //===============================updated api====================================
    apiUrl: 'https://api.telesure.co.za/QuickQuotes/ContactRequest.asmx?op=SendContact',
    leadsSubmitter: 'https://ndw.motloungtech.africa/dev/Submitters/leads-submitter.php',
    Username: "upstream",
    Password: "maertspu",
    Gender: "M",
    DateOfBirth: "00:00:00",
    CallBackDateTime: "00:00:00",
    Description: "NA",
    Year: "1900",
    DealerName: "NA",
    QuoteReference:  "",
    king_price_submitter: 'https://ndw.motloungtech.africa/dev/Submitters/king-price-submitter.php',
    //==============================================================================
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
