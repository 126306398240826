import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { environment } from 'src/environments/environment.prod';
import { GoogleApiIntegrationService } from '../google-api-integration.service';
import * as $ from 'jquery';
import { PwDropdownService, PwFirebaseService, PwThemeingService, PwThemeModel } from '@kaleva-media/purple-water-next';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'budget-insurance',
  templateUrl: './budget-insurance.component.html',
  styleUrls: ['./budget-insurance.component.scss']
})
export class BudgetInsuranceComponent implements OnInit {

  agentData: any;
  campaignType: any;
  isSuccess: boolean = false;
  isError: boolean = false;
  message: string = '';
  leadForm: FormGroup;
  formStatus: any = {
    formErrors: {},
    submitClicked: false
  }
  validationMessages: any;
  errorCount: number;
  error: boolean;
  showMessage: boolean;
  API_URL = environment.apiUrl;
  success: boolean = false;
  commentsDefaultValue: string = 'Positive response, Budget Insurance';
  _theme: PwThemeModel;

  constructor(
    private formBuilder: FormBuilder,
    private googleApiIntegrationService: GoogleApiIntegrationService,
    private firebase: PwFirebaseService,
    private theme: PwThemeingService,
    private snackBar: MatSnackBar,
    private dropdownService: PwDropdownService) {

    this.agentData = {
      name: 'agentName',
      label: 'Agent Name:',
      options: [],
      placeholder: 'Select Name'
    };

    this.campaignType = {
      name: 'campaignType',
      label: 'Campaign Type',
      options: [
        // {
        //   name: 'sms',
        //   label: 'SMS',
        //   value: 'BDATR'
        // },
        // {
        //   name: 'warmup',
        //   label: 'Warmup',
        //   value: 'BARTC'
        // },
        {
          name: 'warmup',
          label: 'Warmup',
          value: 'BDNDW'
        },
        // {name: 'BARTO', label: 'Incentive', value: 'BARTO'}
      ],
      placeholder: 'Select Campaign Type'
    };
  }

  ngOnInit() {
    this.createForm();

    $.ajax({
      url: environment.readnamesApi + '?token=' + environment.purpleWaterToken,
      type: 'POST',
      contentType: 'application/json',
      data: JSON.stringify({ range: 'agent-names!A1:G300' }),
      success: (response) => {
        response.map((name, index) => {
          this.agentData.options[index] = { name: 'agent-' + index, label: name };
        });
      },
      error: (error) => {
        console.error('Error fetching agent names:', error);
      }
    });

    this._theme = new PwThemeModel({
      borderColor: '#00aeef',
      textColor: '#00aeef',
      buttonBgColor: '#0095cd',
      buttonTextColor: '#fff',
      errorColor: 'red',
      labelColor: '#000',
      dropdownOptionBgColor: '#0095cd',
      dropdownOptionTextColor: '#fff',
      buttonBorderColor: '#0095cd'
    })
    this.theme.setTheme(this._theme);
  }

  createForm() {
    this.leadForm = this.formBuilder.group({
      name: ['', Validators.required],
      telephone: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(10)]],
      agentName: '',
      carModel: '',
      comments: 'Positive response, Budget Insurance',
      campaignType: ['', Validators.required],
      surname: ['', Validators.required],
    });

    this.validationMessages = {
      name: {
        'required': 'Please enter a valid name'
      },
      surname: {
        'required': 'Please enter a valid surnname'
      },
      campaignType: {
        'required': 'Please select a campaign type'
      },
      telephone: {
        'required': 'Please enter a valid number',
        'maxlength': 'Please enter a number not greater than 10 charaters',
        'minlength': 'Please enter a number not less than 10 charaters'
      }
    }

    this.formStatus['formErrors'] = {
      name: '',
      surname: '',
      telephone: '',
      campaignType: ''
    }

    this.leadForm.valueChanges.subscribe((data) => {
      this.formIsValid();
    });

    this.leadForm.get('comments').setValue(this.commentsDefaultValue);
  }

  submitLead() {
    if (this.leadForm.status !== 'VALID') {
      this.formStatus.submitClicked = true;
      this.formIsValid();
      return;
    }

    let name = this.leadForm.controls['name'].value;
    let surname = this.leadForm.controls['surname'].value;
    let telephone = this.leadForm.controls['telephone'].value;
    let vdn = this.leadForm.get('campaignType').value.value;
    let agentName = this.leadForm.controls['agentName'].value;
    let carModel = this.leadForm.controls['carModel'].value;
    let comments = this.leadForm.controls['comments'].value;
    let apiComment = 'Car Model: ' + carModel + '. Comments: ' + comments;
    let campaignType = this.leadForm.controls['campaignType'].value
    let campaignName = 'budget';

    this.firebase.saveRecord({
      id: telephone,
      name: name,
      surname: surname,
      phone: telephone,
      vdn: "BDNDW",
      comments: comments,
      campaignName: campaignName,
      agentName: agentName.label,
      carModel: carModel,
      date: this.getOptinDate(),
      day: this.getOptinDateObj().day,
      month: this.getOptinDateObj().month,
      year: this.getOptinDateObj().year
    }, 'leads').then(() => {
      this.firebase.updateCount('leads', campaignName).then(() => {
        this.firebase.updateCount('leads');
      });
    });
    var xmlData = `<?xml version="1.0" encoding="utf-8"?>
    <soap:Envelope xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:xsd="http://www.w3.org/2001/XMLSchema" 
    xmlns:soap="http://schemas.xmlsoap.org/soap/envelope/">
     <soap:Header>
      <AuthenticationHeader xmlns="http://tempuri.org/">
        <Username>${environment.Username}</Username>
        <Password>${environment.Password}</Password>
      </AuthenticationHeader>
    </soap:Header>
    <soap:Body>
      <SendContact xmlns="http://tempuri.org/">
        <p_Lead>
          <QuoteReference> </QuoteReference>
          <Title></Title>
          <Gender>${environment.Gender}</Gender>
          <FirstName>${name}</FirstName>
          <Surname>${surname}</Surname>
          <MobileNumber>${telephone}</MobileNumber>
          <DateOfBirth>${environment.DateOfBirth}</DateOfBirth>
          <CallBackDateTime>${environment.CallBackDateTime}</CallBackDateTime>
          <Description>${environment.Description}</Description>
          <VDN>BDNDW</VDN>
          <BrokerCode>BUDNDWFIN</BrokerCode>
          <CompanyName></CompanyName>
          <Comments></Comments>
          <EmailAddress></EmailAddress>
          <IncompleteLeadNo></IncompleteLeadNo>
          <AddressLine1></AddressLine1>
          <AddressLine2></AddressLine2>
          <SuburbName></SuburbName>
          <PostalCode></PostalCode>
          <VehicleDetails>
            <Description>NA</Description>
            <Year>1900</Year>
            <MNMCode></MNMCode>
            <Colour></Colour>
            <VeehicleKey></VeehicleKey>
            <RegistrationNumber></RegistrationNumber>
            <DealerDetails>
            <DealerName>NA</DealerName>
            <ContactPerson></ContactPerson>
            <TelephoneNumber></TelephoneNumber>
            <EmailAddress></EmailAddress>
            <ChassisNumber></ChassisNumber>
            <EngineNumber></EngineNumber>
            <FinanceHouse></FinanceHouse>
            <DealerCode></DealerCode>
            </DealerDetails>
          </VehicleDetails>
        <ErrorStatus>
        </ErrorStatus>
        <ErrorDescription>
        </ErrorDescription>
        </p_Lead>
      </SendContact>
    </soap:Body>
  </soap:Envelope>`;

  $.post(environment.leadsSubmitter,{xmlData: xmlData})
    .done(function(response) {
      console.log(response);
      this.message = 'Your message has been sent. Well be in touch soon.'
    }).fail(function(xhr, status, error) {
      this.message = 'not submitted.'
  });
  this.googleApiIntegrationService.writeToSheetTemplate({
    firstname: name,
    surname: surname,
    phone: telephone,
    product: campaignType.value,
    agentName: agentName.label,
    day: new Date().getDate() + 'th',
    month: new Date().toLocaleDateString('en-GB', { month: '2-digit', year: 'numeric' }),
    year: new Date().toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit' }),
    status: this.message,
    comments: apiComment
  },'budget-insurance!A1:D9');  
  this.message = "Fresh Lead Submitted!";
  this.snackBar.open(this.message, 'Ok!', {
      verticalPosition: "bottom", // Allowed values are  'top' | 'bottom'
      horizontalPosition: "center" // Allowed values are 'start' | 'center' | 'end' | 'left' | 'right'
  });
  this.resetForm();
    // $.post(this.API_URL, {
    //   // name: name,
    //   // tel: telephone,
    //   // vdn: campaignType.value,
    //   // comments: apiComment
    //   FirstName: name,
    //   Surname: surname,
    //   MobileNumber: telephone,
    //   Username: environment.Username,
    //   Password: environment.Password,
    //   Gender: environment.Gender,
    //   DateOfBirth: environment.DateOfBirth,
    //   CallBackDateTime: environment.CallBackDateTime,
    //   Description:environment.Description,
    //   Year: "2024",
    //   DealerName: environment.DealerName,
    //   QuoteReference: environment.QuoteReference,
    //   VDN: vdn.code,
    //   BrokerCode: 'TELEM'
    // }, (response, status) => {
    //   this.error = true;
    //   this.showMessage = true;

    //   console.log(response);

    //   let parser = new DOMParser();
    //   let doc = parser.parseFromString(response, 'text/html');

    //   if (status === "success") {
    //     this.message = "Fresh Lead Submitted!"

    //     let apiResponseTitle = this.sanitizeString($(doc).find('title')[0].innerText);

    //     if (apiResponseTitle.toLowerCase() !== 'error') {
    //       let apiResponseSuccess = this.sanitizeString($(doc).find('font')[0].innerText);
    //       this.message = apiResponseSuccess;
    //     } else {
    //       let apiResponseError = this.sanitizeString($(doc).find('#lblErrorDefault')[0].innerText);
    //       this.message = apiResponseError;
    //     }

    //     this.googleApiIntegrationService.writeToBudgetInsuranceSheet({
    //       firstname: name,
    //       surname: surname,
    //       phone: telephone,
    //       product: campaignType.value,
    //       agentName: agentName.label,
    //       status: this.message,
    //       comments: apiComment
    //     });

    //     this.snackBar.open(this.message, 'Ok!', {
    //       verticalPosition: "bottom", // Allowed values are  'top' | 'bottom'
    //       horizontalPosition: "center" // Allowed values are 'start' | 'center' | 'end' | 'left' | 'right'
    //     });

    //     this.resetForm();
    //   } else {
    //     if (status === "error") {
    //       this.message = "Oops, an error has occured. Please try again."
    //       this.isError = true;
    //       this.isSuccess = false;

    //       this.googleApiIntegrationService.writeToBudgetInsuranceSheet({
    //         firstname: name,
    //         surname: surname,
    //         phone: telephone,
    //         product: campaignType.value,
    //         agentName: agentName.label,
    //         status: 'not submitted',
    //         comments: apiComment
    //       });
    //     }
    //   }
    // }, "html");
  }

  resetForm() {
    this.dropdownService.resetDropdown('campaignType');
    this.dropdownService.resetDropdown('agentName');
    this.leadForm.reset();
  }

  formIsValid() {
    if (!this.leadForm) {
      return;
    }

    const form = this.leadForm;
    this.errorCount = 0;

    // tslint:disable-next-line:forin
    for (const field in this.formStatus['formErrors']) {
      // clear previous error message (if any)
      this.formStatus['formErrors'][field] = '';
      const control = form.get(field);

      if (control && control.dirty && !control.valid) {
        const messages = this.validationMessages[field];
        // tslint:disable-next-line:forin

        for (const key in control.errors) {

          this.formStatus['formErrors'][field] += messages[key] + ' ';

          this.errorCount++;
        }

      }
    }
  }

  sanitizeString(str){
    str = str.replace(/[^a-z0-9áéíóúñü \.,_-]/gim,"");
    return str.trim();
  }

  getOptinDate() : string {
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0');
    var yyyy = today.getFullYear();
    let strDate = dd + '/' + mm + '/' + yyyy;
    return strDate;
  }

  getOptinDateObj() : any {
    var today = new Date();
    let dateObj = {};
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0');
    var yyyy = today.getFullYear();
    let strDate = dd + '/' + mm + '/' + yyyy;
    dateObj['day'] = dd;
    dateObj['month'] = mm;
    dateObj['year'] = yyyy;
    dateObj['date'] = strDate;
    return dateObj;
  }
}
