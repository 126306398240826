import { Component, OnInit } from '@angular/core';
import { PwFirebaseService } from '@kaleva-media/purple-water-next';
import { Firestore, collection, query, where, getDocs } from '@angular/fire/firestore';

@Component({
  selector: 'campaign-overview',
  templateUrl: './campaign-overview.component.html',
  styleUrls: ['./campaign-overview.component.scss']
})
export class CampaignOverviewComponent implements OnInit {

  single: any[];
  view: any[] = [850, 400];
  data: any;

  colorScheme = {
    domain: ['#95BEE6', '#C3141A', '#F9A800', '#A56774', '#0095cd', '#B01D1F']
  };
  cardColor: string = '#232837';
  campaigns: any[] = [
    {name: '1life', value: 0},
    {name: 'autoAndGeneral', value: 0},
    {name: 'dialDirect', value: 0},
    {name: 'firstForWomen', value: 0},
    {name: 'budget', value: 0},
    {name: 'autoAndGeneralVas', value: 0},
    {name: 'budgetInsuranceVas', value: 0},
  ];
  showOverView: boolean[] = [];
  gradient: boolean = true;

  constructor(private firebase: PwFirebaseService, private firestore: Firestore) {
  }

  ngOnInit(): void {
    let dataReceived = false;
    this.campaigns.map((campaign, i) => {
      this.firebase.getCollectionSize('leads/campaigns/' + campaign.name).then((e) => {
        if (e.data()) {
          this.campaigns[i].value = e.data().total;
          dataReceived = false;
          this.campaigns.sort((a, b) => b.value - a.value);
        }
      }).then(() => {
        dataReceived = true;
        this.showOverView.push(dataReceived)
        dataReceived = false;
      });
    });
  }

  onSelect(event) {
    console.log(event);
  }

  formatDate(date: Date): string {
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // January is 0!
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  }

  //TODO: implement on FE
  async getLeadsSubmittedToday() {
    const todayString = this.formatDate(new Date());
    const leadsRef = collection(this.firestore, 'leads');
    const q = query(leadsRef, where('campaignName', '==', todayString));
    const querySnapshot = await getDocs(q);
    return querySnapshot.docs.map(doc => doc.data());
  }
}
