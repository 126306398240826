
<div class="mtd mtd__container">
  <div class="mtd__wrapper">
      <div class="mtd__heading">
          <h1>Month to Date Stats</h1>
          <span class="header__sub-heading">
              All leads submitted from the beginning of the month. <br/>
              <small>(You can only view these stats 3 times a day)</small>
          </span>
      </div>
  </div>
  <div class="mtd__body">
      <div class="mtd__graph-container" *ngIf="leadsByCampaign">
        <h2>MTD by Campaign</h2>
        <ngx-charts-pie-grid
          [view]="view"
          [scheme]="colorScheme"
          [results]="leadsByCampaign"
          (select)="onSelect($event)"
          style="fill: #fff;">
        </ngx-charts-pie-grid>
      </div>

      <div class="mtd__graph-container" *ngIf="leadsByAgent">
        <h2>MTD by Agent</h2>
        <ngx-charts-bar-horizontal
          [view]="barView"
          [scheme]="barColorScheme"
          [results]="leadsByAgent"
          [gradient]="gradient"
          [xAxis]="showXAxis"
          [yAxis]="showYAxis"
          [legend]="barShowLegend"
          [showXAxisLabel]="showXAxisLabel"
          [showYAxisLabel]="showYAxisLabel"
          [xAxisLabel]="xAxisLabel"
          [yAxisLabel]="yAxisLabel"
          (select)="onSelectBar($event)"
          (activate)="onActivate($event)"
          (deactivate)="onDeactivate($event)"
          style="fill: #fff;">
        </ngx-charts-bar-horizontal>
      </div>
  </div>
</div>
