import { Injectable } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class FormServiceService {

  private errorCount: number;
  private formGroup: FormGroup;
  private formStatus: any = {};
  private validationMessages: any = {};

  constructor(private formBuilder: FormBuilder) { }

  public createNewForm(controls: any[]) : any{
    let controlsObj = {};
    let control;

    controls.map((ctrl, i) => {
      if (ctrl.validation) {
        control = new FormControl('');

        Object.keys(ctrl.validation).map((validationKey, i) => {
          control.setValidators(ctrl.validation[validationKey].validator);
          controlsObj[ctrl.controlName] = control;
        });
      }
    });
    
    console.log(controlsObj)

    this.formGroup = this.formBuilder.group(controlsObj);
    this.createFormStatus(controls);
    this.createValidationMessage(controls);

    this.formGroup.valueChanges.subscribe(() => {
      this.formIsValid(this.formGroup, this.getFormStatus(), this.getValidationMessages());
    });
  }

  private createValidationMessage(controls: any[]) {
    controls.map((ctrl, i) => {
      Object.keys(ctrl.validation).map((validationKey) => {
        this.validationMessages[ctrl.controlName] = {};
        this.validationMessages[ctrl.controlName][validationKey] = ctrl.validation[validationKey].message;
      });
    });
  }

  private createFormStatus(controls: any[]) {
    controls.map((ctrl, i) => {
      this.formStatus['formErrors'] = {};
      this.formStatus['formErrors'][ctrl.controlName] = '';
    });
  }

  public getFormGroup(): FormGroup {
    return this.formGroup;
  }

  public getFormStatus() {
    return this.formStatus;
  }

  public getValidationMessages() {
    return this.validationMessages
  }

  public getForm() {
    return {
      formGroup: this.formGroup,
      formStatus: this.formStatus,
      validationMessages: this.validationMessages
    }
  }

  public formIsValid(formGroup: FormGroup, formStatus: any, validationMessages: any) {
    if (!formGroup) {
      return;
    }

    const form = formGroup;
    this.errorCount = 0;

    for (const field in formStatus['formErrors']) {

      formStatus['formErrors'][field] = '';
      const control = form.get(field);

      if (control && control.dirty && !control.valid) {
        const messages = validationMessages[field];

        for (const key in control.errors) {
          formStatus['formErrors'][field] += messages[key] + ' ';
          this.errorCount++;
        }
      }
    }
  }
}
