import { Component, OnInit } from '@angular/core';
import { Firestore, collection, query, where, getDocs } from '@angular/fire/firestore';
import { CounterService } from '../counter.service';
import { Router } from '@angular/router';

@Component({
  selector: 'mtd',
  templateUrl: './mtd.component.html',
  styleUrls: ['./mtd.component.scss']
})
export class MtdComponent implements OnInit {

  mtdData: any[] = [];
  mtdTotal: number = 0;
  leadsByAgent: any;
  leadsByCampaign: any;
  view: any[] = [800, 400];

  // options
  showLegend: boolean = true;
  showLabels: boolean = true;

  colorScheme = {
    domain: ['#FF3233', '#FF32FF', '#CC32FF', '#0000FF', '#34CCFF', '#33FEFF']
  };

  // bar
  barView: any[] = [800, 2000];

  // options
  showXAxis: boolean = true;
  showYAxis: boolean = true;
  gradient: boolean = false;
  barShowLegend: boolean = false;
  showXAxisLabel: boolean = true;
  yAxisLabel: string = 'Agent';
  showYAxisLabel: boolean = true;
  xAxisLabel: string = 'Submitted Leads';

  barColorScheme = {
    domain: ['#FF3233', '#FF32FF', '#CC32FF', '#0000FF', '#34CCFF', '#33FEFF']
  };

  constructor(private firestore: Firestore, private counterService: CounterService, private router: Router) { }

  ngOnInit(): void {
    this.checkAccess('NDW').then((canAccess: any) => {
      console.log(canAccess);
      if (canAccess) {
        this.getLeadsFromBeginningOfMonth().then((e: any) => {
          console.log(e)
          this.mtdData = e;
          this.mtdTotal = this.mtdData.length;
          this.leadsByAgent = this.formatLeadsByAgent(this.mtdData)
          this.leadsByCampaign = this.formatLeadsByCampaign(this.mtdData);
          console.log(this.leadsByAgent)
          console.log(this.leadsByCampaign)
        })
      } else {
        // show some error message
        alert('View Limit Reached for today!');
        this.router.navigate(['/']);
        console.log('Limit reached')
      }
    }).catch((err) => {
      console.log(err)
    })
  }

  async checkAccess(userId: string) {
    const canAccess = await this.counterService.canAccessRoute(userId);
    return canAccess;
  }

  private pad(num: number): string {
    return num.toString().padStart(2, '0');
  }

  async getLeadsFromBeginningOfMonth() {
    const today = new Date();
    const startOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
    const startDay = this.pad(startOfMonth.getDate());
    const endDay = this.pad(today.getDate());
    const month = this.pad(today.getMonth() + 1); // January is 0!
    const year = today.getFullYear();

    const leadsRef = collection(this.firestore, 'leads');
    const q = query(
      leadsRef,
      where('year', '==', year),
      where('month', '==', month),
      where('day', '>=', startDay),
      where('day', '<=', endDay)
    );
    const querySnapshot = await getDocs(q);
    return querySnapshot.docs.map(doc => doc.data());
  }

  formatLeadsByAgent(leads: any[]) {
    const leadsByAgent = {};

    leads.forEach(lead => {
      if (leadsByAgent[lead.agentName]) {
        leadsByAgent[lead.agentName] += 1;
      } else {
        leadsByAgent[lead.agentName] = 1;
      }
    });

    return Object.entries(leadsByAgent).map(([name, value]) => ({ name, value }));
  }


  formatLeadsByCampaign(leads: any[]) {
    const leadsByCampaign = {};

    leads.forEach(lead => {
      if (leadsByCampaign[lead.campaignName]) {
        leadsByCampaign[lead.campaignName] += 1;
      } else {
        leadsByCampaign[lead.campaignName] = 1;
      }
    });

    return Object.entries(leadsByCampaign).map(([name, value]) => ({ name, value }));
  }

  onSelect(event) {}

  onSelectBar(event) {}

  onActivate(event) {}

  onDeactivate(event) {}

}
