import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { environment } from '../../environments/environment';
import * as $ from 'jquery';
import { GoogleApiIntegrationService } from '../google-api-integration.service'

@Component({
  selector: 'flow-submit-hollard-lead',
  templateUrl: './flow-submit-hollard-lead.component.html',
  styleUrls: ['./flow-submit-hollard-lead.component.css']
})
export class FlowSubmitHollardLeadComponent implements OnInit {

  dropdownData: any;
  isSuccess: boolean = false;
  isError: boolean = false;
  message: string = '';
  leadForm: FormGroup;
  agentData: any;
  formStatus: any = {
    formErrors: {},
    submitClicked: false
  }
  validationMessages: any;
  errorCount: number;
  error: boolean;
  showMessage: boolean;
  API_URL = environment.apiUrl;
  // API_URL_ALLLIFE = environment.apiUrlAllLife;
  API_URL_HOLLARD = 'http://insurecover.co.za/luhambo-leads-management/hollardSubmitter.php';
  success: boolean = false;

  CAMPAIGN_ID: string = 'fH25Tk2Z';
  CAMPAIGN_PASSWORD: string = '7vpWcyXT';
  SUPPLIER_CODE: string = 'FMG';
  CAMPAIGN_NAME: string = 'Atarocol_Fun_SMS';

  randomChar: any = '12345'

  constructor(private formBuilder: FormBuilder, private googleApiIntegrationService: GoogleApiIntegrationService) {
    this.dropdownData = {
      name: 'contactNumberType',
      label: 'Contact Number Type:',
      options: [
        {
          name: 'HOME',
          label: 'Home'
        }, 
        {
          name: 'OFFICE', 
          label: 'Office'
        },
        {
          name: 'MOBILE', 
          label: 'Mobile'
        },
        {
          name: 'UNKNOWN', 
          label: 'Unknown'
        }
      ],
      placeholder: 'Select contact number type'
    };

    this.agentData = {
      name: 'agentName',
      label: 'Agent Name:',
      options: [],
      placeholder: 'Select Name'
    };
  }

  ngOnInit() {
    this.createForm();

    this.googleApiIntegrationService.reatAtNames();
    this.googleApiIntegrationService.names.subscribe((names) => {
      // console.log(names);
      let namesArray = JSON.parse(names);
      namesArray.map((nameArray, index) => {
        this.agentData.options[index] = {name: 'agent-' + index, label: nameArray[0]};
      });
    });
  }

  submitLead() {
    console.log(this.leadForm)
    if (this.leadForm.status !== 'VALID') {
      this.markAllAsDirty();
      this.formStatus.submitClicked = true;
      this.formIsValid();
      return;
    }
    
    let firstname = this.leadForm.controls['firstname'].value;
    let lastName = this.leadForm.controls['lastName'].value;
    let email = this.leadForm.controls['email'].value;
    let contactNumber = this.leadForm.controls['contactNumber'].value;
    let title = this.leadForm.controls['title'].value;
    let agentName = this.leadForm.controls['agentName'].value;

    $.post(this.API_URL_HOLLARD, {
      firstname: firstname,
      lastName: lastName,
      email: email,
      contactNumber: contactNumber,
      campaignName: this.CAMPAIGN_NAME,
      title: title
    }, (response, status) => {
      this.error = true;
      this.showMessage = true;
      console.log(response);

      if (status === "success") {
        this.message = "Lead Submitted!"
        this.isSuccess = true;
        this.isError = false;
        this.success = true;

        this.googleApiIntegrationService.writeAtHollard({
          firstName: firstname,
          surname: lastName,
          phone: contactNumber,
          email: email,
          agentName: agentName.label,
          status: "Creation Successful"
        });

      } else {
        if (status === "error") {
          this.message = "Oops, an error has occured. Please try again."
          this.isError = true;
          this.isSuccess = false;
          
          this.googleApiIntegrationService.writeAtHollard({
            firstName: firstname,
            surname: lastName,
            phone: contactNumber,
            email: email,
            agentName: agentName.label,
            status: "Error Submitting"
          });
        }
      }
    }, "html");
  }

  createForm() {
    this.leadForm = this.formBuilder.group({
      firstname: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      contactNumber: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(10)]],
      title: ['', Validators.required],
      agentName: '',
    });

    this.validationMessages = {
      contactNumber: {
        'required': 'Please enter a valid contact number',
        'maxlength': 'Please enter a number not greater than 10 charaters',
        'minlength': 'Please enter a number not less than 10 charaters'
      },
      firstname: {
        'required': 'Please select enter a first name'
      },
      lastName: {
        'required': 'Please select enter a last name'
      },
      title: {
        'required': 'Please select enter a last name'
      },
      email: {
        'required': 'Please enter a valid email',
        'email': 'Please enter a valid email'
      }
    }

    this.formStatus['formErrors'] = {
      contactNumber: '',
      firstname: '',
      lastName: '',
      email: '',
      title: '',
    }

    this.leadForm.valueChanges.subscribe((data) => {
      this.formIsValid();
    })
  }

  markAllAsDirty() {
    Object.keys(this.leadForm.controls).map((x) => {
      this.leadForm.controls[x].markAsDirty();
    })
  }

  formIsValid() {
    if (!this.leadForm) {
      return;
    }

    const form = this.leadForm;
    this.errorCount = 0;

    for (const field in this.formStatus['formErrors']) {
      // clear previous error message (if any)
      this.formStatus['formErrors'][field] = '';
      const control = form.get(field);

      if (control && control.dirty && !control.valid) {
        const messages = this.validationMessages[field];

        for (const key in control.errors) {

          this.formStatus['formErrors'][field] += messages[key] + ' ';

          this.errorCount++;
        }

      }
    }
  }

  resetForm() {
    this.leadForm.reset();
    this.success = false;
  }
}