<div class="submit-lead">
	<div class="header">
		<img src="/luhambo-leads-management/assets/budget-insurance-logo.jpg" />
	</div>

	<div class="body" [ngClass]="{'flip': success, 'back': success}">
		<div *ngIf="!success">
			<pw-input [data]="{name: 'name', label: 'Name:', type: 'text'}" [parentFormGroup]="budgetInsuranceForm" [formStatus]="formStatus"></pw-input>

			<pw-input [data]="{name: 'surname', label: 'Surname:', type: 'text'}" [parentFormGroup]="budgetInsuranceForm" [formStatus]="formStatus"></pw-input>

			<pw-input [data]="{name: 'telephone', label: 'Cellphone:', type: 'number'}" [parentFormGroup]="budgetInsuranceForm" [formStatus]="formStatus"></pw-input>
			
			<pw-dropdown [data]="agentData" [parentFormGroup]="budgetInsuranceForm"></pw-dropdown>

			<pw-button [data]="{name: 'submit', label: 'Submit', disabled: false}" (click)="submitLead()"></pw-button>
		</div>

		<div class="back message" *ngIf="success">
			<div class="message__heading">
				Lead Submitted
			</div>

			<div class="message__message">
				Your details have been successfully submitted. An assistant will contact you shortly. To help speed up
				the process, have your ID ready.
			</div>
		</div>
	</div>

	<div class="reset" *ngIf="success" >
		<pw-button [data]="{name: 'ok', label: 'Ok', disabled: false}" (click)="resetForm()"></pw-button>
	</div>

	<div class="footer">
		Copyright 2016 Budget Insurance Company Ltd. All rights reserved. Budget is an authorized financial service
		provider (FSP number: FSP 18178). 
		Long-term policies underwritten by 1Life, FSP 24769. Personal Loans Powered by RCS, 
		a registered Credit and authorised Financial Services Provider. NCRCP 38/FSP 44481.
	</div>
</div>