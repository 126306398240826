import { EventEmitter, Injectable, Output, Directive } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { BehaviorSubject, Observable } from 'rxjs';



@Directive()
@Injectable({
  providedIn: 'root'
})
export class UserService {
  @Output() user: EventEmitter<any> = new EventEmitter();
  public isUserLoggedIn: BehaviorSubject<boolean>;
  timer:any;
  

  constructor( private cookieService: CookieService) {
    this.isUserLoggedIn = new BehaviorSubject<boolean>(false);
    if(this.cookieService.get('user')){ 
      this.isUserLoggedIn.next(true);
    } else {
      this.isUserLoggedIn.next(false);
    }
   }

  getUser() {
    // expiry time for cookie
    const dateNow = new Date();
    dateNow.setMinutes(dateNow.getMinutes() + 30);
    // dateNow.setSeconds(dateNow.getSeconds() + 30);
    const user = this.cookieService.get('user');
  // Reset cookie so it does not expire
    this.cookieService.set('user', user, dateNow);
    // this.user.emit(user);

    return user;
  }

  startTimer(){
   this.timer = setInterval(() => {
      if (this.cookieService.check('user') === false) {
        this.isUserLoggedIn.next(false);
      }
     }, 300000); // millisecinds
  }

  stopTimer() {
    clearInterval(this.timer);
  }

  logout() {
    this.cookieService.deleteAll();
    this.isUserLoggedIn.next(false);
  }
}
