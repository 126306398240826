<div class="submit-lead">
  <div class="header">
    <img src="/luhambo-leads-management/assets/Logo.png"/>
  </div>

  <div class="body" [ngClass]="{'flip': success, 'back': success}">
    <div *ngIf="!success">
      <pw-input [data]="{name: 'name', label: 'Name:', type: 'text'}" [parentFormGroup]="leadForm" [formStatus]="formStatus"></pw-input>
      
      <pw-input [data]="{name: 'surname', label: 'Surname:', type: 'text'}" [parentFormGroup]="leadForm" [formStatus]="formStatus"></pw-input>

      <pw-input [data]="{name: 'telephone', label: 'Cellphone:', type: 'number'}" [parentFormGroup]="leadForm" [formStatus]="formStatus"></pw-input>

      <pw-dropdown [data]="dropdownData" [parentFormGroup]="leadForm"></pw-dropdown>
      
      <pw-dropdown [data]="agentData" [parentFormGroup]="leadForm"></pw-dropdown>

      <pw-button [data]="{name: 'submit', label: 'Submit', disabled: false}" (click)="submitLead()"></pw-button>
    </div>

    <div class="back message" *ngIf="success">
      <div class="message__heading">
        Lead Submitted
      </div>

      <div class="message__message">
        Your details have been successfully submitted. An assistant will contact you shortly. To help speed up the process, have your ID ready.
      </div>
    </div>
  </div>

  <div class="footer">
    Copyright 2017. 1Life Insurance Limited. All rights reserved. 1Life Insurance Limited is an authorised financial services provider (FSP 24769). <br/>
    *Terms and conditions apply. <br/>
    Risk profile dependent.
  </div>
</div>
