import { Injectable } from '@angular/core';
import { Firestore, doc, getDoc, updateDoc, serverTimestamp } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root'
})
export class CounterService {
  constructor(private firestore: Firestore) {}

  private isSameDay(date1: Date, date2: Date): boolean {
    return date1.getFullYear() === date2.getFullYear() &&
           date1.getMonth() === date2.getMonth() &&
           date1.getDate() === date2.getDate();
  }

  async canAccessRoute(userId: string): Promise<boolean> {
    const counterRef = doc(this.firestore, 'counters', userId);
    const counterSnap = await getDoc(counterRef);

    if (!counterSnap.exists()) {
      // Handle the case where the counter doesn't exist for the user
      return false;
    }

    const data = counterSnap.data();
    const limit = data.limit; // Assuming there is a 'limit' field
    let currentCount = data.count; // Assuming there is a 'count' field
    const lastAccessed = data.lastAccessed?.toDate(); // Convert Firestore Timestamp to JavaScript Date

    const today = new Date();

    if (!lastAccessed || !this.isSameDay(lastAccessed, today)) {
      // If last accessed date is not today, reset the count
      currentCount = 0;
    }

    console.log(currentCount)
    console.log(limit)
    console.log(currentCount < limit)

    if (currentCount < limit) {
      // Update the count and last accessed date, then allow access
      await updateDoc(counterRef, {
        count: currentCount + 1,
        lastAccessed: serverTimestamp() // Update with current server timestamp
      });
      return true;
    } else {
      // Limit reached, access denied
      return false;
    }
  }
}
