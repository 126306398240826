export const environment = {
  production: true,
  // apiUrl: 'https://repository.isservices.co.za/bannerprocessing/process.aspx',
  oldURl : 'https://repository.isservices.co.za/bannerprocessing/process.aspx',
  apiUrlAllLife: 'https://leads.alllife.co.za:8443/magnum/direct_api/submit_lead',
  apiUrl_hollard: 'http://fullserve.osmdialnow.co.za/vicidial/non_agent_api.php',
  localeApi: 'https://www.jeffasenmusic.co.za/api_caller.php',
  apiUrl_kingprice: 'http://ws.kinmaertspugprice.co.za/prews/WebServices/Cavalry/Outbound/LeadsService.asmx?wdsl',
  apiUrl_budget: 'https://services.quickinsure.co.za/api/lead',
  apiUrl_carloan: 'https://returnxdigital.leadbyte.co.uk/integration?slice=5e1d6717da842669799576',
  apiUrl_sanlam: 'http://tracking.affcoza.com/aff_c?offer_id=2072&aff_id=18897',
  crmApiUrl: 'https://luhambomarketing.co.za/Luhambo-CRM/apis/db-api.php',
  kingPriceWriterApi: 'https://ndw.motloungtech.africa/Writers/kingPriceSheetWriter.php',
  kingPriceSubmitterApi: 'https://ndwfinancialservices.co.za/Submitters/submit-kingprice-lead.php',
  readNamesApi: 'https://atarocol.co.za/leads-application/readers/at-readnames.php',
  lifeWriterApi: 'https://atarocol.co.za/leads-application/writers/atLifeWriter.php',
  autoAndGeneralWriterApi: 'https://atarocol.co.za/leads-application/writers/autoAndGeneralWriter.php',
  budgetInsuranceWriterApi: 'https://atarocol.co.za/leads-application/writers/budgetInsuranceWriter.php',
  budgetInsuranceWriterVasApi: 'https://ndw.motloungtech.africa/Writers/budgetInsuranceVasWriter.php',
  autoAndGeneralVasWriterApi: 'https://ndw.motloungtech.africa/Writers/autoAndGeneralVasWriter.php',
  firstForWomenWriterApi: 'https://atarocol.co.za/leads-application/writers/firstForWomenWriter.php',
  dialDirectWriterApi: 'https://atarocol.co.za/leads-application/writers/dialDirectWriter.php',
  readnamesApi: 'https://api-hub.shayelasolutions.co.za/api/v1/campaigns/read-sheet/ndw-financial',
  purpleWaterWritterApi:'https://api-hub.shayelasolutions.co.za/api/v1/campaigns/write-to-sheet/ndw-financial',
  purpleWaterToken: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJBMDUxRlEyMlpQMSIsInN1YiI6IlUwNTFXOUtBODhLIiwiZXhwIjoxNzUxOTc4OTgwLCJpYXQiOjE3MjA0NDI5ODB9.G5Tqy8ILM2da_83I8nuGIZBaLB5MJWF2W5O3gk73t44",
  readerApi: 'http://gilela.kalevamedia.com/apis/at-readnames.php',
  tracker: {
    trackerUsername: 'Gilela',
    trackerPassword: 'Tracker@112#',
    sourceId: '91459',
    trackerSubmitterApi: 'http://gilela.kalevamedia.com/apis/tracker-submitter.php',
    trackerWriterApi: 'http://gilela.kalevamedia.com/apis/tracker-writer.php'
  },
  // firebaseConfig: {
  //   apiKey: "AIzaSyA_I_SyIHGnpij0-iL6ALlcct7kxrNyoLg",
  //   authDomain: "gilela.firebaseapp.com",
  //   projectId: "gilela",
  //   storageBucket: "gilela.appspot.com",
  //   messagingSenderId: "943107733161",
  //   appId: "1:943107733161:web:2808fb60f7735095049bb8",
  //   measurementId: "G-9CSBLBNY9K"
  // },
  cartrack: {
    token: 'f8fd084a-7e88-4c09-b8e7-2b080bfd93d1',
    campaignUid: '3ea9b287-bf99-4582-a625-44dc411a37e8',
    apiUrl: 'https://ndw.motloungtech.africa/Submitters/car-track-submitter.php',
    writerUrl: 'https://ndw.motloungtech.africa/Writers/fvCarTrackSheetWriter.php',
    readUrl: 'https://ndw.motloungtech.africa/Readers/readnames.php'
  },
  firebaseConfig: {
    apiKey: "AIzaSyDFvFfWCmZFgiJC8XwMpQBVvV20k4v6Ol8",
    authDomain: "potholeregistrydb.firebaseapp.com",
    projectId: "potholeregistrydb",
    storageBucket: "potholeregistrydb.appspot.com",
    messagingSenderId: "247147878293",
    appId: "1:247147878293:web:9167d0f10aaee07dadc89c",
    measurementId: "G-NY3KE1Q2DD"
  },
  campaigns: [{
    name: 'virseker',
    title: 'Virseker',
    type: 'custom', //affiliate || telesure || custom
    path: 'virseker',
    imgSrc: '',
    campaignMeta: {
      url: 'https://repository.isservices.co.za/bannerprocessing/process.aspx',
      currentDate: {
        set: false,
        apiKey: 'date'
      },
      dateOfBirth: {
        set: false,
        apiKey: '' // value of dateOfBirth must live in controls as "dateOfBirth"
      }
    },
    integrationDocUrl: '',
    apiUrl: 'https://atarocol.co.za/leads-application/api/submitter.php', // see readme to run light-web-server, this will help you run your php locally
    inputs: [
      {
        name: 'name',
        label: 'Firstname',
        type: 'input',
        inputType: 'text',
        placeholder: '',
        validations: [
          {
            validation: 'required',
            message: 'Please enter valid Firstname'
          }
        ]
      },
      {
        name: 'surname',
        label: 'Surname',
        type: 'input',
        inputType: 'text',
        placeholder: '',
        validations: [
          {
            validation: 'required',
            message: 'Please enter valid Surname'
          }
        ]
      },
      {
        name: 'tel',
        label: 'Phone Number',
        type: 'input',
        inputType: 'number',
        placeholder: '',
        validations: [
          {
            validation: 'required',
            message: 'Please enter valid Phone Number'
          },
          {
            validation: 'minlength',
            message: 'Please enter valid Phone Number'
          },
          {
            validation: 'maxlength',
            message: 'Please enter valid Phone Number'
          }
        ]
      },
      {
        name: 'vdn',
        label: 'Campaign Type',
        type: 'dropdown',
        inputType: '',
        placeholder: '',
        options: [
          {name: 'WVATW', label: 'Warmup', value: 'WVATW'}
        ],
        validations: [
        ]
      },
      {
        name: 'agentName',
        label: 'Agent Name:',
        type: 'dropdown',
        inputType: '',
        placeholder: '',
        options: [
        ],
        validations: [
        ]
      }
    ],
    sheetConfig: {
      applicationName: 'atarocol leads application',
      spreadSheetId: '1JJhXNhnIBKQiQimgE0ygv6GJpS6nPY3H46KCyLRph-Q',
      developerKey: 'AIzaSyB86WbfRkO-rdcJAPuWBNqQbSftEKBy9E0',
      writer: {
        sheetName: 'Virseker',
        url: 'https://atarocol.co.za/leads-application/api/writer-template.php'
      },
      reader: {
        sheetName: 'agent-names',
        url: 'https://atarocol.co.za/leads-application/api/readnames-template.php',
        set: true
      }
    }
  }],
    //===============================ndw leads====================================
    apiUrl: 'https://api.telesure.co.za/QuickQuotes/ContactRequest.asmx?op=SendContact',
    leadsSubmitter: 'https://ndw.motloungtech.africa/Submitters/leads-submitter.php',
    Username: "upstream",
    Password: "maertspu",
    Gender: "M",
    DateOfBirth: "00:00:00",
    CallBackDateTime: "00:00:00",
    Description: "NA",
    Year: "1900",
    DealerName: "NA",
    QuoteReference:  " ",
    king_price_submitter: 'https://ndw.motloungtech.africa/Submitters/king-price-submitter.php',
    //==============================================================================
};
