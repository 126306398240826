<mat-toolbar *ngIf="this.navigationIsVisible"class="navigation__header mat-toolbar mat-toolbar-single-row">
  <div class="toolbar-content">
    <a routerLink="/" class="logo-wrapper">
      <!-- <img class="logo" src="./assets/logo.png"/> -->
    </a>
    <!-- <div>
      <button mat-icon-button class="example-icon" aria-label="Example icon-button with menu icon">
        <mat-icon>menu</mat-icon>
      </button>
    </div> -->
    <span class="example-spacer"></span>

    <div class="toolbar-action__wrapper" *ngIf="menuActionsVisible">
      <a routerLink="#" class="toolbar-action">
        <mat-icon>account_circle</mat-icon>
        <span class="action-label"> User Name </span>
      </a>
      <span class="example-spacer"></span>

      <a class="toolbar-action" (click)="logout()" routerLink="/login">
        <mat-icon>login</mat-icon>
        <span class="action-label"> logout </span>
      </a>
    </div>
  </div>
</mat-toolbar>

<mat-drawer-container class="example-container">

  <mat-drawer class="navigation__drawer" mode="side" opened *ngIf="navigationIsVisible == true">
    <div class="navigation-links__container">

      <mat-tree [dataSource]="nestedDataSource" [treeControl]="nestedTreeControl" class="navigation-links__link-wrapper">

        <mat-tree-node *matTreeNodeDef="let node">
          <a id="{{node.id}}" [routerLink]="node.url" class="mat-tree-node" [ngClass]="{
              'navigation-links__sub-link': node.isChild,
              'navigation-links__main-link': !node.isChild,
              'navigation-links__main-link--routing-disabled': node.disableRouting,
              'navigation-links__main-link--disabled navigation-links__main-link--routing-disabled': node.disabled}">
            <mat-icon *ngIf="!node.isChild">{{node.icon}}</mat-icon>
            {{ node.label }}
          </a>
        </mat-tree-node>

        <mat-nested-tree-node *matTreeNodeDef="let node; when: nodeHasChildren; let i = index;" class="navigation-links">
          <span matTreeNodeToggle class="navigation-links__main-link-wrapper">
            <a id="{{node.id}}" [routerLink]="node.url" class="mat-tree-node navigation-links__main-link"
            [ngClass]="{
              'navigation-links__main-link--routing-disabled': node.disableRouting,
              'navigation-links__main-link--disabled': node.disabled,
              'navigation-links__main-link--active': nestedTreeControl.isExpanded(node)}">
              <mat-icon *ngIf="!node.isChild">{{node.icon}}</mat-icon>
              {{ node.label }}
            </a>

            <span class="navigation-links__sub-link-wrapper" [class.example-tree-invisible]="!nestedTreeControl.isExpanded(node)">
              <ng-container matTreeNodeOutlet></ng-container>
            </span>
          </span>
        </mat-nested-tree-node>

      </mat-tree>
    </div>
  </mat-drawer>

  <mat-drawer-content class="navigation__content">
    <router-outlet></router-outlet>
  </mat-drawer-content>

</mat-drawer-container>
