<div class="submit-lead">
    <div class="header">
      <img src="../../assets/budget-insurance-logo.jpg"/>
    </div>
  
    <div class="body" [ngClass]="{'flip': success, 'back': success}">
      <div *ngIf="!success">
        <pw-input [data]="{name: 'name', label: 'Name:', type: 'text'}" [parentFormGroup]="leadForm" [formStatus]="formStatus"></pw-input>
        
        <pw-input [data]="{name: 'surname', label: 'Surname:', type: 'text'}" [parentFormGroup]="leadForm" [formStatus]="formStatus"></pw-input>
  
        <pw-input [data]="{name: 'telephone', label: 'Cellphone:', type: 'number'}" [parentFormGroup]="leadForm" [formStatus]="formStatus"></pw-input>
        
        <pw-dropdown [data]="agentData" [parentFormGroup]="leadForm"></pw-dropdown>
        
        <pw-dropdown [data]="campaignType" [parentFormGroup]="leadForm"></pw-dropdown>

        <pw-input [data]="{name: 'carModel', label: 'Car Model:', type: 'test'}" [parentFormGroup]="leadForm" [formStatus]="formStatus"></pw-input>

        <pw-textarea [data]="{name: 'comments', label: 'Comments'}" [parentFormGroup]="leadForm" [formStatus]="formStatus"></pw-textarea>
  
        <pw-button [data]="{name: 'submit', label: 'Submit', disabled: false}" (click)="submitLead()"></pw-button>
      </div>
    </div>
  
    <div class="footer">
      &copy; Copyright 2022 Kaleva Media (PTY) Ltd.
    </div>
  </div>
  